import { registerApplication, start, addErrorHandler } from 'single-spa'

registerApplication({
  name: '@polis/ricerca',
  app: () => System.import('/polis/ricerca/main.js'),
  activeWhen: ['/polis/ui/ricerca'],
})

start({
  urlRerouteOnly: true,
})

addErrorHandler(err => {
  const elContent = document.getElementById('content')
  elContent.innerHTML =
    '<h1>Non è possibile caricare la specifica applicazione</h1>' +
    '<h4>' +
    err +
    '</h4>'
})

window.addEventListener('popstate', evt => {
  const location = window.location.pathname
  if (evt.singleSpa && location.endsWith('/')) {
    window.history.replaceState({}, location, location.slice(0, -1))
  }
})
